import React, { useEffect, useState } from 'react';  
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styles from '../../styles/ManageAccountsPage.module.css';

const ManageAccountsPage = () => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [notes, setNotes] = useState([]);
  const [viewNewClients, setViewNewClients] = useState(true);
  const [showNotesForClient, setShowNotesForClient] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL?.replace(/\/$/, '') || 'https://api-backend.full-trucking-service.com/api';
        let token = sessionStorage.getItem('token'); // Obține token-ul din sessionStorage

        const response = await axios.get(`${apiUrl}/admin/manage`, {
          withCredentials: true,
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`, // Adaugă token-ul JWT aici
          }
        });

        // Verifică tipul de conținut și statusul cererii
        if (response.status === 200 && response.headers['content-type'].includes('application/json')) {
          if (Array.isArray(response.data)) {
            setClients(response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
          } else {
            console.error('Expected an array of clients, but got:', response.data);
          }
        } else {
          console.error('Server returned non-JSON response:', response);
        }
      } catch (err) {
        console.error('Error fetching clients:', err);

        if (err.response?.status === 401) {
          console.log('Token expirat sau invalid. Încerci să îl reînnoiești?');
          alert('Sesiunea ta a expirat. Te rugăm să te autentifici din nou.');
          sessionStorage.removeItem('token'); // Șterge token-ul expirat
          navigate('/admin'); // Redirect la pagina de login
        }
      }
    };

    fetchClients();
  }, [navigate]);

  const handleActivate = async (email) => {
    const clientToActivate = clients.find((client) => client.email === email);
    if (!clientToActivate) return;

    const { subscriptionType, contractStart, numberOfTrucks, numberOfDrivers, numberOfAccountants, numberOfDispatchers } = clientToActivate;

    if (!numberOfTrucks || !numberOfDrivers || !numberOfAccountants || !numberOfDispatchers) {
      alert('Completează toate câmpurile obligatorii pentru activare!');
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL?.replace(/\/$/, '') || 'https://api-backend.full-trucking-service.com/api';
      const token = sessionStorage.getItem('token'); // Obține token-ul din sessionStorage

      const response = await axios.post(
        `${apiUrl}/admin/activate`,
        {
          email,
          subscriptionType,
          contractStart,
          contractEnd: calculateContractEnd(contractStart, subscriptionType), // Calculăm și trimitem contractEnd
          numberOfTrucks,
          numberOfTrucks,
          numberOfDrivers,
          numberOfAccountants,
          numberOfDispatchers,
        },
        {
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // Adaugă token-ul JWT aici
          },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        alert('Clientul a fost activat cu succes.');
        setClients(clients.map((client) => (client.email === email ? { ...client, active: true } : client)));
      } else {
        alert('Activarea a eșuat.');
      }
    } catch (err) {
      console.error('Activation error:', err);
      alert('Eroare de server. Te rog încearcă din nou.');
    }
  };

  const handleDeactivate = async (email) => {
    const clientToDeactivate = clients.find((client) => client.email === email);
    if (!clientToDeactivate) return;

    const token = sessionStorage.getItem('token'); // Obține token-ul din sessionStorage

    try {
      const apiUrl = process.env.REACT_APP_API_URL?.replace(/\/$/, '') || 'https://api-backend.full-trucking-service.com/api';

      const response = await axios.post(
        `${apiUrl}/admin/deactivate`,
        { email },
        {
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // Adaugă token-ul JWT aici
          },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        alert('Clientul a fost dezactivat cu succes.');
        setClients(clients.map((client) => (client.email === email ? { ...client, active: false } : client)));
      } else {
        alert('Dezactivarea a eșuat.');
      }
    } catch (err) {
      console.error('Deactivation error:', err);
      alert('Eroare de server. Te rog încearcă din nou.');
    }
  };

  const handleEdit = (client) => {
    setSelectedClient(client);
    setNotes(client.notes || []);
    setShowNotesForClient(null);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const token = sessionStorage.getItem('token'); // Obține token-ul din sessionStorage

    try {
      const apiUrl = process.env.REACT_APP_API_URL?.replace(/\/$/, '') || 'https://api-backend.full-trucking-service.com/api';

      const updatedClient = {
        ...selectedClient,
        subscriptionType: selectedClient.subscriptionType,
      contractStart: selectedClient.contractStart,
      contractEnd: selectedClient.contractEnd, // Include contractEnd
      notes,
      numberOfTrucks: selectedClient.numberOfTrucks,
      numberOfAccountants: selectedClient.numberOfAccountants,
        numberOfDispatchers: selectedClient.numberOfDispatchers, // Adaugă numberOfDispatchers
     
    };
      const response = await axios.put(`${apiUrl}/admin/client/${selectedClient._id}`, updatedClient, {
        headers: {
          'Authorization': `Bearer ${token}`, // Adaugă token-ul JWT aici
        },
        withCredentials: true,
      });

      setClients(clients.map((client) => (client._id === selectedClient._id ? updatedClient : client)));
      setSelectedClient(null);
    } catch (err) {
      console.error('Save error:', err);
      alert('A apărut o eroare. Te rog încearcă din nou.');
    }
  };

  const handleDelete = async (id) => {
    const token = sessionStorage.getItem('token'); // Obține token-ul din sessionStorage

    if (window.confirm('Ești sigur că vrei să ștergi acest cont?')) {
      try {
        const apiUrl = process.env.REACT_APP_API_URL?.replace(/\/$/, '') || 'https://api-backend.full-trucking-service.com/api';

        await axios.delete(`${apiUrl}/admin/client/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`, // Adaugă token-ul JWT aici
          },
          withCredentials: true,
        });

        setClients(clients.filter((client) => client._id !== id));
      } catch (err) {
        console.error('Delete error:', err);
        alert('A apărut o eroare. Te rog încearcă din nou.');
      }
    }
  };

  const handleSubscriptionChange = (type) => {
    const contractEnd = calculateContractEnd(selectedClient.contractStart, type);
    setSelectedClient({ ...selectedClient, subscriptionType: type, contractEnd });
  };

  const calculateContractEnd = (startDate, subscriptionType) => {
    const date = new Date(startDate);
    if (isNaN(date)) return '';

    if (subscriptionType === 'Monthly') {
      date.setMonth(date.getMonth() + 1);
    } else if (subscriptionType === 'Yearly') {
      date.setFullYear(date.getFullYear() + 1);
    } else if (subscriptionType === '3 Day Test') {
      date.setDate(date.getDate() + 3);
    }

    return date.toISOString().split('T')[0];
  };

  const handleContractStartChange = (e) => {
    const contractStart = e.target.value;
    const contractEnd = calculateContractEnd(contractStart, selectedClient.subscriptionType);
    setSelectedClient({ ...selectedClient, contractStart, contractEnd });
  };

  const handleAddNote = () => {
    setNotes([...notes, '']);
  };

  const handleNoteChange = (index, value) => {
    const newNotes = [...notes];
    newNotes[index] = value;
    setNotes(newNotes);
  };

  const toggleShowNotes = (clientEmail) => {
    setShowNotesForClient(clientEmail === showNotesForClient ? null : clientEmail);
  };

  const filterClients = (newClients) => {
    return clients.filter((client) => (newClients ? !client.active : client.active));
  };

  const handlePlanChange = async (email, newPlan) => {
    const token = sessionStorage.getItem('token'); // Obține token-ul din sessionStorage
    const filteredClients = filterClients(viewNewClients).filter(client =>
      Object.values(client).some(value =>
        value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
    try {
      const apiUrl = process.env.REACT_APP_API_URL?.replace(/\/$/, '') || 'https://api-backend.full-trucking-service.com/api';
      
      const response = await axios.post(
        `${apiUrl}/admin/change-plan`,
        { email, newPlan },
        {
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // Adaugă token-ul JWT aici
          },
          withCredentials: true
        }
      );

      if (response.status === 200) {
        alert('Pachetul a fost actualizat cu succes.');
        setClients(clients.map(client => client.email === email ? { ...client, package: newPlan } : client));
      } else {
        alert('Eroare la actualizarea pachetului.');
      }
    } catch (err) {
      console.error('Error updating subscription plan:', err);
      alert('A apărut o eroare. Te rog încearcă din nou.');
    }
  };

  const renderPlanOptions = (currentPlan) => {
    switch (currentPlan) {
      case 'Ultimate':
        return ['Premium', 'Standard', 'Basic'];
      case 'Premium':
        return ['Ultimate', 'Standard', 'Basic'];
      case 'Standard':
        return ['Ultimate', 'Premium', 'Basic'];
      case 'Basic':
        return ['Ultimate', 'Premium', 'Standard'];
      default:
        return [];
    }
  };

  return (
    <div className={styles.manageAccountsPage}>
      <h1 className={styles.title}>Gestionare Conturi</h1>
      <div className={styles.buttonGroup}>
        <button onClick={() => setViewNewClients(false)}>Conturi Active</button>
        <button onClick={() => setViewNewClients(true)}>Conturi Noi</button>
        <button onClick={() => navigate('/dashboard')}>Dashboard</button>
        <button
          onClick={async () => {
            const apiUrl = process.env.REACT_APP_API_URL?.replace(/\/$/, '') || 'https://api-backend.full-trucking-service.com/api';
            const token = sessionStorage.getItem('token'); // Obține token-ul din sessionStorage

            await axios.post(`${apiUrl}/admin/auth/logout`, {}, {
              headers: {
                'Authorization': `Bearer ${token}`, // Adaugă token-ul JWT aici
              },
              withCredentials: true,
            });
            navigate('/admin');
          }}
        >
          Logout
        </button>
      </div>
      <input
        type="text"
        placeholder="Caută clienți..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className={styles.searchBar}
      />
      <div className={styles.content}>
        <ul className={styles.clientsList}>
          {filterClients(viewNewClients).map((client) => (
            <li key={client.email} className={`${styles.clientItem} ${client.active ? styles.active : styles.inactive}`}>
              <div className={styles.clientDetails}>
                <span>{client.companyName}</span>
                <span>{client.companyAddress}</span>
                <span>{client.firstName}</span>
                <span>{client.lastName}</span>
                <span>{client.email}</span>
                <span>{client.phone}</span>
                <span>{client.fax}</span>
                <span>{client.mcNumber}</span>
                <span>{client.package}</span>
              </div>
              <div className={styles.subscriptionActions}>
                <select
                  value={client.package}
                  onChange={(e) => handlePlanChange(client.email, e.target.value)}
                  className={styles.packageSelect}
                >
                  <option value="" disabled>Selectează un pachet</option>
                  {renderPlanOptions(client.package).map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div className={styles.clientActions}>
                {!client.active && <button onClick={() => handleActivate(client.email)}>Activează</button>}
                {client.active && <button onClick={() => handleDeactivate(client.email)}>Dezactivează</button>}
                <button onClick={() => handleEdit(client)}>Editează</button>
                <button onClick={() => handleDelete(client._id)}>Șterge</button>
                <button onClick={() => toggleShowNotes(client.email)}>Vezi Notițele</button>
              </div>
              {showNotesForClient === client.email && (
                <div className={styles.notesSection}>
                  {client.notes && client.notes.map((note, index) => <p key={index}>{note}</p>)}
                </div>
              )}
            </li>
          ))}
        </ul>

        {selectedClient && (
          <div className={styles.editClientModal}>
            <h2>Editează informațiile clientului</h2>
            <form onSubmit={handleSave}>
              <input
                type="text"
                name="companyName"
                value={selectedClient.companyName || ''}
                onChange={(e) => setSelectedClient({ ...selectedClient, companyName: e.target.value })}
                required
              />
              <input
                type="text"
                name="companyAddress"
                value={selectedClient.companyAddress || ''}
                onChange={(e) => setSelectedClient({ ...selectedClient, companyAddress: e.target.value })}
                required
              />
              <input
                type="text"
                name="firstName"
                value={selectedClient.firstName || ''}
                onChange={(e) => setSelectedClient({ ...selectedClient, firstName: e.target.value })}
                required
              />
              <input
                type="text"
                name="lastName"
                value={selectedClient.lastName || ''}
                onChange={(e) => setSelectedClient({ ...selectedClient, lastName: e.target.value })}
                required
              />
              <input
                type="email"
                name="email"
                value={selectedClient.email || ''}
                onChange={(e) => setSelectedClient({ ...selectedClient, email: e.target.value })}
                required
              />
              <input
                type="phone"
                name="phone"
                value={selectedClient.phone || ''}
                onChange={(e) => setSelectedClient({ ...selectedClient, phone: e.target.value })}
                required
              />
  <input
                type="fax"
                name="fax"
                value={selectedClient.fax || ''}
                onChange={(e) => setSelectedClient({ ...selectedClient, fax: e.target.value })}
                required
              />

              <input
                type="text"
                name="mcNumber"
                value={selectedClient.mcNumber || ''}
                onChange={(e) => setSelectedClient({ ...selectedClient, mcNumber: e.target.value })}
                required
              />

              <label>Număr de Camioane</label>
              <input
                type="number"
                name="numberOfTrucks"
                value={selectedClient.numberOfTrucks || ''}
                onChange={(e) => setSelectedClient({ ...selectedClient, numberOfTrucks: e.target.value })}
                required
              />

              <label>Număr de Șoferi</label>
              <input
                type="number"
                name="numberOfDrivers"
                value={selectedClient.numberOfDrivers || ''}
                onChange={(e) => setSelectedClient({ ...selectedClient, numberOfDrivers: e.target.value })}
                required
              />

           {/* Câmp pentru numărul de contabili */}
<label>Număr de Contabili</label>
<input
  type="number"
  name="numberOfAccountants"
  value={selectedClient.numberOfAccountants || ''}
  onChange={(e) => setSelectedClient({ ...selectedClient, numberOfAccountants: e.target.value })}
  required
/>

{/* Câmp pentru numărul de dispatchers */}
<label>Număr de Dispatchers</label>
<input
  type="number"
  name="numberOfDispatchers"
  value={selectedClient.numberOfDispatchers || ''}
  onChange={(e) => setSelectedClient({ ...selectedClient, numberOfDispatchers: e.target.value })}
  required
/>

              <select
                name="subscriptionType"
                value={selectedClient.subscriptionType || ''}
                onChange={(e) => handleSubscriptionChange(e.target.value)}
              >
                <option value="Monthly">Lunar</option>
                <option value="Yearly">Anual</option>
                <option value="3 Day Test">Test de 3 zile</option>
              </select>

              <input
                type="date"
                name="contractStart"
                value={selectedClient.contractStart ? selectedClient.contractStart.split('T')[0] : ''}
                onChange={handleContractStartChange}
                required
              />
              <input
                type="date"
                name="contractEnd"
                value={selectedClient.contractEnd ? selectedClient.contractEnd.split('T')[0] : ''}
                readOnly
                required
              />

              <div className={styles.notesSection}>
                <h3>Notițe</h3>
                {notes.map((note, index) => (
                  <div key={index}>
                    <input type="text" value={note} onChange={(e) => handleNoteChange(index, e.target.value)} />
                  </div>
                ))}
                <button type="button" onClick={handleAddNote}>
                  +
                </button>
              </div>

              <button type="submit">Salvează modificările</button>
              <button type="button" onClick={() => setSelectedClient(null)}>
                Anulează
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default ManageAccountsPage;
